window.addEventListener(
  "message",
  function (event) {
    console.log(event);
    const iframe = document.getElementById("dynamicIframe");
    
    if (event.data.type === "setHeight" && event.data.device === "desktop" && iframe) {
      iframe.classList.remove("glowing-container");
      iframe.style.height = "80%";
      iframe.style.width = "40%";
     
      
    }
    if (event.data.type === "setHeight" && event.data.device === "mobile" && iframe) {
      iframe.classList.remove("glowing-container");
      iframe.style.height = "98%";
      iframe.style.width = "97%";
      
     
    }
    if (event.data.type === "unsetHeight" && event.data.device === "desktop" && iframe) {
      iframe.style.height = "60px";
      iframe.style.width = "180px";
      iframe.classList.add("glowing-container");
     
    }
    if (event.data.type === "unsetHeight" && event.data.device === "mobile" && iframe) {
      iframe.style.height = "60px";
      iframe.style.width = "180px";
      iframe.classList.add("glowing-container");
      
    }
  },
  false
);
